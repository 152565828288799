
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import Message from '@/components/mixins/Message.vue';
import BlocksLayout from '@/components/BlocksLayout.vue';
import { Sections } from '@/types/SectionTypes';

@Component({
  name: 'Dashboard',
  components: {
    BlocksLayout,
  },
})
export default class Dashboard extends Vue {
  private loading = false;

  private sections: Sections = [];

  private parentComponent = 'Dashboard';

  mounted(): void {
    this.fetchData();
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first') {
    this.loading = true;

    import('@/graphql/queries/dashboard-layout')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
      }))
      .then((response: { data: { dashboardLayout: { sections: Sections }}}) => {
        this.sections = response.data.dashboardLayout.sections;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

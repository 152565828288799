

import {
  Component,
  Vue,
  Watch,
  Prop,
} from 'vue-property-decorator';
import { Sections } from '@/types/SectionTypes';

const DossierAuditBlock = () => import(
  /* webpackChunkName: "dossier-audit-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/auditBlocks/DossierAuditBlock.vue'
);
const DossierAuditsBlock = () => import(
  /* webpackChunkName: "dossier-audits-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/auditBlocks/DossierAuditsBlock.vue'
);
const DossierFilesBlock = () => import(
  /* webpackChunkName: "dossier-files-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/fileBlocks/DossierFilesBlock.vue'
);
const DetailNoteBlock = () => import(
  /* webpackChunkName: "detail-dossier-task-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/noteBlocks/DetailNoteBlock.vue'
);
const DossierNotesBlock = () => import(
  /* webpackChunkName: "dossier-notes-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/noteBlocks/DossierNotesBlock.vue'
);
const ArchivedTasksBlock = () => import(
  /* webpackChunkName: "archived-dossier-tasks-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/ArchivedTasksBlock.vue'
);
const DetailTaskBlock = () => import(
  /* webpackChunkName: "detail-dossier-task-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/DetailTaskBlock.vue'
);
const OpenTasksBlock = () => import(
  /* webpackChunkName: "open-tasks-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/taskBlocks/OpenTasksBlock.vue'
);
const ChartBlock = () => import(
  /* webpackChunkName: "chart-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/ChartBlock.vue'
);
const DashboardChartBlock = () => import(
  /* webpackChunkName: "dashboard-chart-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/ChartBlock.vue'
);
const FilesBlock = () => import(
  /* webpackChunkName: "files-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/FilesBlock.vue'
);
const FormBlock = () => import(
  /* webpackChunkName: "form-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/FormBlock.vue'
);
const HeaderBlock = () => import(
  /* webpackChunkName: "header-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/HeaderBlock.vue'
);
const ListBlock = () => import(
  /* webpackChunkName: "list-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/ListBlock.vue'
);
const DashboardCounterBlock = () => import(
  /* webpackChunkName: "dashboard-counter-block" */
  /* webpackPrefetch: false */
  '@/components/detailBlocks/CounterBlock.vue'
);
const DashboardListBlock = () => import(
  /* webpackChunkName: "dashboard-dossier-type-list" */
  /* webpackPrefetch: false */
  '@/components/dashboard/DashboardDossierTypeList.vue'
);
const DashboardTaskModuleBlock = () => import(
  /* webpackChunkName: "dashboard-task-module-block" */
  /* webpackPrefetch: false */
  '@/components/dashboard/DashboardTaskModuleBlock.vue'
);
const DashboardContactModuleBlock = () => import(
  /* webpackChunkName: "dashboard-contact-module-block" */
  /* webpackPrefetch: false */
  '@/components/dashboard/DashboardContactModuleBlock.vue'
);

@Component({
  name: 'Detail',
  components: {
    DossierAuditBlock,
    DossierAuditsBlock,
    DossierFilesBlock,
    DetailNoteBlock,
    DossierNotesBlock,
    ArchivedTasksBlock,
    DetailTaskBlock,
    OpenTasksBlock,
    ChartBlock,
    FilesBlock,
    FormBlock,
    HeaderBlock,
    ListBlock,
    DashboardCounterBlock,
    DashboardChartBlock,
    DashboardListBlock,
    DashboardTaskModuleBlock,
    DashboardContactModuleBlock,
  },
})
export default class BlocksLayout extends Vue {
  @Prop({ required: true })
  protected readonly sections!: Sections;

  @Prop()
  protected readonly navigationSlug?: string;

  @Prop()
  protected readonly dossierTypeSlug?: string;

  @Prop()
  protected readonly dossierId?: string;

  private isReady = false;

  @Watch('sections', { deep: true })
  private checkForReady(): void {
    this.isReady = this.sections.every((section) => section.columns.every((column) => column.blocks.every((block) => block.ready)));

    if (this.isReady) {
      this.$emit('isReady');
    }
  }
}

